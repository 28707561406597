.carousel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-left: 1rem;
        margin-bottom: 1rem;
    
            & > a {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                width: fit-content;
                color: #277da1;
                font-size: 2rem;
                font-weight: 600;

                &:hover {
                    color: #f9c74f
                }

                & > h2 {
                    font-size: 2rem;
                    white-space: nowrap;
                }

                & > i {
                    display: grid;
                    place-items: center;
                    font-size: 1.5rem;
                }
            }
    
            & > p {
                font-size: 1.2rem;
                font-weight: 500;
            }
    }

    & > .carouselInner {
        display: flex;
        flex: 2;
    
        justify-content: space-between;
        align-items: center;
        
        border-radius: 2rem;
        background-repeat: no-repeat;
        padding: 0 0.25rem;
    
        transition: ease-in-out 250ms;
        background-size: contain;
        background-position: left;

        & > .arrow {
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
            display: grid;
            place-items: center;
            color: #fff;
            font-size: 2rem;
            &:hover {
                cursor: pointer;
                background-color: #ffffff88;
                color: #333;
            }
        }
    }

    & > .cInfo {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        color: #222;
        margin: 0.25rem 0.5rem;

        & > .blurb {
            flex: 1;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 0.5rem;
            border-radius: 1rem;
            background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), #ffffff22);
            text-align: center;
        }
    }
}

@media(min-width: 600px) {
    .carousel {
        .carouselInner {
            flex: 5;
            background-size: cover;
            background-position: center;
        }
    }
}