.navToggle {
    position: relative;
    font-size: 3.5rem;
    width: 100%;
    height: 4rem;
    margin-bottom: 0.25rem;

    & > i {
        color: #f9c74fcc;
        margin: 0.25rem 0 0 1.5rem;
    }
}
.navCont {
    width: 100%;
    .navbar {
        display: none;
        position: absolute;
        top: 4.25rem;
        left: 1%;
        right: 1%;
        min-height: 700px;
        height: 90vh;
        z-index: 5;

        border-radius: 2rem;
        background: linear-gradient(to right bottom, #222, #999999aa);

        &.mobileOpen {
            display: block;
        }

        & > img {
            display: none;
        }

        & > ul {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            width: 100%;
            list-style: none;
            padding: 1rem 0;

            & > li {
                flex: 1;
                & i {
                    font-size: 6rem;
                }
            }
        }

        & a {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #fff;

            text-decoration: none;
            flex: 1;
            transition: ease-in 200ms;
        }

        a[data]::after {
            content: attr(data);
            display: block;
            position: relative;
            transform: scale(0);
            transition: ease-in 200ms;
            font-size: 2.5rem;
        }

        a[data]:hover:focus {
            color: #f9c74f;
        }

        a[data]:hover::after {
            transform: scale(1);
            color: #fff;
        }

        .active a[data]::after {
            transform: scale(1);
            color: #fff;
        }

        .active a {
            color: #f9c74f;
        }
    }
}

@media (min-width: 600px) {
    .navToggle {
        display: none;
    }

    .navCont {
        .navbar {
            position: unset;
            display: block;
            width: 90%;
            margin: 0.5rem auto 0;
            min-height: unset;
            height: 7.5rem;
            top: 0.25rem;
            left: 5%;
            background: #fff;
            background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), #ffffff22);

            & > ul {
                flex-direction: row;
                padding: 0.5rem 0;

                & > li {
                    & i {
                        font-size: 4rem;
                    }
                    a[data]::after {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 950px) {
    .navCont {
        width: 15%;
        max-width: 200px;
        .navbar, .navbar.mobileOpen {
            display: flex;
            flex-direction: column;
            height: 80vh;
            min-height: 600px;
            width: 90%;
            
            margin-top: 2rem;
            padding: 0.25rem;

            & > img {
                display: block;
                height: 120px;
                width: 120px;
                object-fit: cover;
                border-radius: 50%;
                margin: auto;
            }

            & > ul {
                height: 80%;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, 1fr);
                padding: 0.5rem 0 0;
                & > li {
                    & i {
                        font-size: 4.5rem;
                    }
                }
            }
            
        }
    }
}
