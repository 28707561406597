html {
  background: linear-gradient(to left top, #f9c74f, #277da1);
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 325px;
  width: 100vw;
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}