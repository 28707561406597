.aboutInner {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer;

    & > .aboutCard {

        & > .cardInner {
            display: flex;
            flex-direction: column;
            
            color: #fff;

            height: 100%;
            width: 100%;

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;

            & > .cardContent {
                overflow: hidden;
                border-radius: 2rem;
                
                & > h2 {
                position: relative;
                width: fit-content;
                font-size: 2rem;
                margin: 1rem 0 3rem 0.5rem;
                text-shadow: 1px 1px #333;

                @media(max-width: 600px){
                    font-size: 1.4rem;
                    margin-left: 0;
                    padding-left: 0.75rem;
                }
                

                    &::after {
                        content: "";
                        position: absolute;
                        padding: 0.15rem 0;
                        right: 120%;
                        bottom: -0.5rem;
                        width: 100%;
                        background: #f9c74f;
                        border-radius: 2rem;
                        
                        transition: transform 600ms ease-in;
                    }
                }
                
                & > p {
                    visibility: hidden;
                    transform: scale(0);
                    align-self: center;
                    width: 100%;
                    padding: 1rem;
                    transition: transform 600ms ease-in;
                    text-align: left;
                    line-height: 1.2;
                    text-indent: 1.5rem;
                    
                    font-size: 1.2rem;
                    font-weight: 600;
                    text-shadow: 1px 1px #333;
                    @media(min-width: 400px){
                        line-height: 1.5;
                        padding: 2rem;
                    }
                }

                & > i {
                    display: none;
                    position: absolute;
                    font-size: 2.5rem;
                    top: 1rem;
                    right: 0.5rem;
                    @media (min-width: 420px) {
                        top: 1.5rem;
                        right: 2rem;
                    }
                }
            }
        }

        &  > .cardInner.cardFocus {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            cursor: auto;
            border-radius: 1rem;
        
            & > .cardContent {
                align-self: center;
                height: 100%;
                width: 100%;
                background: linear-gradient(#000000b3,#3e3c3c90);

                & > p {
                    transform: scale(1);
                    visibility: visible;
                }
            
                & > h2::after {

                    transform: translateX(120%);
                }

                & > i {
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }
}

/* Asymmetrical Card Sizing >>>*/
.aboutCard:nth-of-type(1){
    height: 35%;
    width: 40%;
    @media(max-width: 400px){
        width: 45%;
    }
}

.aboutCard:nth-of-type(2){
    height: 35%;
    width: 60%;
    @media(max-width: 400px){
        width: 55%;
    }
}

.aboutCard:nth-of-type(3){
    height: 65%;
    width: 70%;
    @media(max-width: 400px){
        width: 60%
    }
}

.aboutCard:nth-of-type(4){
    height: 65%;
    width: 30%;
    @media(max-width: 400px){
        width: 40%
    }
}
