.slide {
    display: flex;
    flex-direction: row;
    flex: 1;

    color: #333;
    border-radius: 1rem;
    padding: 0 0.25rem;
    & > .slideTitle {
        display: flex;
        flex-direction: column;
        width: fit-content;

        & > h2 {
            font-size: 1.3rem;
            font-weight: 700;
            text-align: left;
            height: 30%;
            text-decoration: underline;
        }

        & > ul {
            height: 70%;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            list-style: none;
            align-items: flex-start;
            font-weight: 600;
            padding-bottom: 0.25rem;
            flex: 1 0 10px;
            overflow: hidden;
            & > li {
                font-size: 0.9rem;
                text-align: center;
                background-color: #f19720;

                padding: 0 0.5rem;
                border-radius: 1rem;
                white-space: nowrap;
            }
        }
    }

    & > p {
        font-size: 0.8rem;
        padding: 0 0.5rem;
        font-weight: 600;
        width: auto;
        border-left: 2px solid #277da1;
        margin: 0.25rem;
        overflow-y: auto;
    }
}

@media (min-width: 600px) {
    .slide {
        & > p {
            font-size: 0.9rem;
        }
    }
}

@media (min-width: 900px) {
    .slide {
        & > p {
            font-weight: 500;
            font-size: 1rem;
        }
    }
}
