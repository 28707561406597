@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
--sidebar-flex:1.5;
--condiv-flex:6;
--fontsize-p:1.2rem;
--typing-font:3rem;

}
* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  font-family: 'Raleway';
  font-size: 16px;
  overflow-x: hidden;
}

.App {
  min-height: 800px;
  height: 100vh;
  padding-bottom: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px){
    min-height: 950px;
  }

  @media (min-width: 950px) {
    min-height: 750px;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.content_container {
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  overflow: hidden;
}


/* Skills CSS >>> */

.skills {
  background-image: url(./img/skills.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* <<< Skills CSS */