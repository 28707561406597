.social {
  position: absolute;
  height: fit-content;
  width: 200px;
  top: 1rem;
  right: 2rem;
  display: flex;
  justify-content: space-between;
  z-index: 3;

  & > .socialItem {
    cursor: pointer;
    position: relative;
    transition: content 1000ms ease-out;

    &::before {
      content: attr(tooltip);
      display: none;
      color: black;
      position: absolute;
      top: 2.5rem;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      font-weight: 600;
      color: #277da1;
      background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), #ffffff22);
      border-radius: 2rem;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      white-space: nowrap;
    }

    &.email:active {
      & > i {
        color: #fff;
      }
      &::before {
        display: block;
      }
    }
  }

  & i {
    font-size: 2.5rem;
    color: #277da1;
  }
}

@media (min-width: 600px) {
  .social {
    position: static;
    margin: 0 auto;
    .socialItem::before {
      top: -2.2rem;
    }
  }
}

@media (min-width: 950px) {
  .social {
    margin: 0 2.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 2rem;

    & > .socialItem:hover::before {
      display: block;
    }

    & i {
      font-size: 2.5rem;
      color: #277da1;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
