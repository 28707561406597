:root {
  --angleX: 0deg;
  --angleY: 0deg;
}

// STYLING FOR ALL TEXT INSIDE CARD >>
.contentCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 3fr 2fr;
  gap: 0.5rem;
  padding: 0.75rem;
  text-align: center;

  & > h2 {
    text-transform: capitalize;
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
    text-align: left;
    animation: fadeIn 2000ms;
    & > span {
      display: block;
      text-align: center;
      color: #f9c74f;
      animation: fadeIn 4000ms;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  & > img {
    height: 190px;
    width: auto;
    border-radius: 50%;
    margin: 0 auto;
  }

  & > p {
    color: #eee;
    font-weight: 600;
    padding: 0 1rem;
    & > span {
      display: block;
    }
  }
}

@media (min-width: 600px) {
  .contentCont {
    & > img {
      height: 250px;
    }
  }
}
// << STYLING FOR ALL TEXT INSIDE CARD

.homeInner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transform: perspective(1500px);

  & > .pseudo-parallax-card-container {
    position: relative;
    width: 300px;
    height: 525px;
    display: flex;
    flex-flow: row wrap;

    & > .mock {
      width: 50%;
      height: 50%;
      z-index: 2;
    }
    & > .mock.left.top:hover ~ .content-container {
      --angleX: 8deg;
      --angleY: -25deg;
    }
    & > .mock.left.bottom:hover ~ .content-container {
      --angleX: -8deg;
      --angleY: -25deg;
    }
    & > .mock.right.top:hover ~ .content-container {
      --angleX: 8deg;
      --angleY: 25deg;
    }
    & > .mock.right.bottom:hover ~ .content-container {
      --angleX: -8deg;
      --angleY: 25deg;
    }

    & > .content-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, #f9c74f, #277da1);
      border-radius: 2rem;
      user-select: none;
      transition: box-shadow 0.5s ease, transform 0.65s ease;
      will-change: transform;
      box-shadow: -10px 5px 10px rgba(0, 0, 0, 0.4);
      transform: translatey(0) rotatex(var(--angleX, 0)) rotatey(var(--angleY, 0)) translatez(-10px);
      filter: brightness(110%);
      @media (min-width: 450px) {
        box-shadow: -20px 10px 20px rgba(0, 0, 0, 0.4);
      }

      & > .message-container {
        & > .message {
          position: absolute;
          display: grid;
          place-items: center;
          top: 35%;
          left: -20%;
          padding: 0 1rem;
          width: fit-content;
          height: 8%;
          z-index: 3;
          color: #277da1;
          font-weight: 600;
          font-size: 1.1rem;
          border-radius: 2rem;
          box-shadow: -20px 10px 10px rgba(0, 0, 0, 0.2), -1px -1px 0px #a0a0a0 inset;
          background: linear-gradient(to left bottom, rgba(255, 255, 255, 0.8), #ffffff22);

          &:nth-of-type(1) {
            top: 70%;
            left: 40%;
          }

          &:nth-of-type(2) {
            top: 80%;
            left: 2%;
          }

          &:nth-of-type(3) {
            top: 90%;
            left: 30%;
          }
        }
      }
    }
  }
}

@media (min-width: 420px) {
  .homeInner {
    & > .pseudo-parallax-card-container {
      width: 325px;
      height: 575px;

      & > .content-container {
        & > .message-container {
          & > .message {
            font-size: 1.3rem;

            &:nth-of-type(3) {
              left: 25%;
            }
          }

          & > .contentCont {
            & > img {
              height: 210px;
            }
            & > p {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .homeInner {
    & > .pseudo-parallax-card-container {
      width: 400px;
      height: 570px;

      & > .content-container {
        width: 400px;
        height: 575px;

        & > .message-container {
          & > .message:nth-of-type(1) {
            top: 70%;
            left: 5%;
          }

          & > .message:nth-of-type(2) {
            top: 75%;
            left: 50%;
          }

          & > .message:nth-of-type(3) {
            top: 85%;
            left: 30%;
          }
        }
      }
    }
  }
}
