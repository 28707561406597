.skillsInner {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr;
    gap: 0.1rem;
    
    background-color: #ffffff22;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 1rem 0;

    & > .skillsSlider {
        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: 1rem;
        width: 125%;
        height: 100%;
        border: 1px solid #277da1;
        background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), #ffffffaa);

        transition: transform ease-in-out 800ms;

        & > .skillIcon {
            font-size: clamp(4rem, 1vw + 3rem, 6rem);
            display: grid;
            place-items: center;
            cursor: pointer;
            width: 20%;
            height: 100%;
            color: #f19720d8;
        }

        &.leftBar {
            left: -101.2%;
            flex-direction: row-reverse;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;

            & > .slide {
                margin-left: 0.25rem;
            }

            &:hover, &:focus {
                transform: translateX(80%);
            }
        }
        
        &.rightBar {
            right: -76.2%;
            flex-direction: row;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;

            & > .slide {
                margin-right: 0.25rem;
            }

            &:hover, &:focus {
                transform: translateX(-80%);
            }
        }
    }
}

@media (min-width: 900px) {
    .skillsInner {
        & > .skillsSlider {
            width: 100%;
            &.leftBar {
                left: -90%;

                &:hover, &:focus {
                    transform: translateX(90%);
                }
            }

            &.rightBar {
                right: -90%;

                &:hover, &:focus {
                    transform: translateX(-90%);
                }
            }
            
            & > .skillIcon {
                width: 10%;
                font-size: 4.5rem;
            }
        }
    }
}